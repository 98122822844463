import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
//Service
import { CompanyService, ICompanyReturn } from '../company/company.service';
import { LoggingService } from '../utils/logging.service';
//Rxjs
import { map } from 'rxjs';

const SERVER_PATH = import.meta.env.NG_APP_API_URL;

export interface IContactStatus {
  label: string;
  value: number;
}

export interface IContactList {
  count: number;
  data: [];
}

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  // Injectable Service
  private readonly http = inject(HttpClient);
  private readonly companyService = inject(CompanyService);
  private readonly loggingService = inject(LoggingService);

  /**
   * Object Contact Status
   */
  private readonly statusList: IContactStatus[] = [
    {
      label: 'general_status',
      value: 0,
    },
    {
      label: 'follow_up_status',
      value: 1,
    },
    {
      label: 'resolve_status',
      value: 2,
    },
    {
      label: 'spam_status',
      value: 3,
    },
  ];

  /**
   * GET CONTACT LIST
   * @description Get a list of contacts, limited to 100 per page
   * @param currentPageNumber
   * @param limit default to 100 items
   */
  getContactList(currentPageNumber = 1, limit = 100) {
    const queryParams = `?page=${currentPageNumber}&limit=${limit}`;

    return this.http
      .get(`${SERVER_PATH}/customer/list/${this.companyService.getCompanyState()?.id}${queryParams}`)
      .pipe(map((res: any) => res));
  }

  /**
   * GET CONTACT BY CONDITION
   * @description Get a list of contacts based on search conditions, limited to 100 per
   * @param conditions
   * @param currentPageNumber
   * @param limit default to 100 items
   */
  getContactByCondition(conditions: any, currentPageNumber = 1, limit = 100) {
    const queryParams = `&page=${currentPageNumber}&limit=${limit}`;

    const keyObj = Object.keys(conditions);
    const filterKey = keyObj
      .map((res, index) => {
        if (index === 0) {
          if (typeof conditions[res] === 'string') {
            // Type String
            return `?${res}=${conditions[res]}`;
          } else {
            // Type Array
            return `?${res}=${conditions[res].toString()}`;
          }
        } else {
          if (typeof conditions[res] === 'string') {
            // Type String
            return `&${res}=${conditions[res]}`;
          } else {
            // Type Array
            return `&${res}=${conditions[res].toString()}`;
          }
        }
      })
      .join('');
    this.loggingService.log('filterKey', filterKey);
    return this.http
      .get(`${SERVER_PATH}/customer/list/${this.companyService.getCompanyState()?.id}${filterKey}${queryParams}`)
      .pipe(map((res: any) => res));
  }

  /**
   * GET TAG LIST
   * @description Get a list of contact tags within a company
   */
  getTagsListAll() {
    const { id } = this.companyService.getCompanyState() as ICompanyReturn;
    return this.http.get(`${SERVER_PATH}/tag/list/${id}`);
  }

  /**
   * PATCH CONTACT
   * @description Update the contact details
   * @param data
   * @param contactId
   */
  patchContact(data: any, contactId: string) {
    const { id } = this.companyService.getCompanyState() as ICompanyReturn;
    const dataSend = {
      ...data,
      compId: id,
    };
    return this.http.patch(`${SERVER_PATH}/customer/${contactId}`, dataSend).pipe(map((res: any) => res));
  }

  /**
   * GET STATUS LIST
   * @description Get list contact status
   */
  getStatusList(): IContactStatus[] {
    return this.statusList;
  }

  /**
   * MANAGE LAYOUT
   * @description Manage the layout of the UI card list
   * @param i
   */
  manageLayout(i: number) {
    let layout: string;

    if ((i + 1) % 3 !== 0) {
      if (i % 2 === 0) {
        layout = 'pr-0 sm:pr-3';
      } else {
        layout = 'md:pr-3';
      }
    } else {
      if (i % 2 === 0) {
        layout = 'pr-0 sm:pr-3 md:pr-0';
      } else {
        layout = '';
      }
    }

    return layout;
  }
}
