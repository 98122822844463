import { inject, Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { LS, StorageService } from '../../core/storage/storage.service';
import { LoggingService } from '../../core/utils/logging.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageConfigService {
  private translocoService = inject(TranslocoService);
  private storageService = inject(StorageService);
  private readonly loggingService = inject(LoggingService);

  constructor() {
    this.loggingService.log('@@@ Lang Service @@@');
  }

  getLangState() {
    return this.storageService.get(LS.lang);
  }

  switchLanguage(language: string) {
    this.translocoService.setActiveLang(language);
    this.setLangState(language);
  }

  setLangState(lang: string) {
    this.storageService.set(LS.lang, lang);
  }
}
