import { HttpClient } from '@angular/common/http';
import { effect, inject, Injectable, signal } from '@angular/core';
import { IUserSystemState } from '../../core/auth/auth.interface';
// Appsync
import { generateClient } from 'aws-amplify/data';
import { type Schema } from '../../../../amplify/data/resource';
// RxJS
import {
  BehaviorSubject,
  catchError,
  concatMap,
  defer,
  EMPTY,
  from,
  map,
  of,
  tap,
  throwError,
  timer,
  toArray,
} from 'rxjs';
import { switchMap } from 'rxjs/operators';
// Service
import { LS, StorageService } from '../../core/storage/storage.service';
import { ICompanyReturn } from '../../core/company/company.service';
import { ContactService } from '../../core/contact/contact.service';
import { CompConnectService } from '../../core/connect/comp-connect.service';
import { OptionsMessageLineService } from '../../pattern/options-message-line/options-message-line.service';
import { SubscriptionFeaturesService } from '../../core/subscription-features/subscription-features.service';
import { AuthStore } from '../../core/auth/auth.store';
import { LoggingService } from '../../core/utils/logging.service';

const SERVER_PATH = import.meta.env.NG_APP_API_URL;

export interface GroupedDataItem {
  date: string;
  items: number[];
}

@Injectable({
  providedIn: 'root',
})
export class CompChatManageService {
  private readonly http = inject(HttpClient);
  private readonly authStore = inject(AuthStore);
  private readonly storageService = inject(StorageService);
  private readonly contactService = inject(ContactService);
  private readonly connectService = inject(CompConnectService);
  private readonly optionsMessageLineService = inject(OptionsMessageLineService);
  private readonly subFeatureService = inject(SubscriptionFeaturesService);
  private readonly loggingService = inject(LoggingService);

  private currentSocialChanelId = signal<string>('');
  private currentConversation = signal<any | undefined>(undefined);
  private displaySignal = signal<boolean>(false);
  private displayMobileSignal = signal<boolean>(false);
  private contactListSignal = signal<any>(undefined);
  private chatMessageSignal = signal<any>(undefined);
  private chatMessageGroupSignal = signal<any>(undefined);
  private errorSendMessageSignal = signal<any[]>([]);
  private nextTokenChatSignal = signal<string | null | undefined>('');
  private converOnSubSignal = signal<any>(undefined);

  private triggerGetConVer = new BehaviorSubject<boolean>(false);
  private triggerGetConVer$ = this.triggerGetConVer.asObservable();

  // Use for Scroll to Bottom when Blocked or Unblocked
  private autoScroll = new BehaviorSubject<boolean>(false);
  private autoScroll$ = this.autoScroll.asObservable();

  private triggerRefresh = new BehaviorSubject<any>(undefined);
  private triggerRefresh$ = this.triggerRefresh.asObservable();

  client = generateClient<Schema>();

  constructor() {
    this.loggingService.warn('@@@ Chat Service @@@ --> Generate Client 💻💻💻', this.client);
    this.setupStream();
  }

  // private messageSubject = new Subject<any>(); // Subject to handle message sending
  private messageB$ = new BehaviorSubject<any>(undefined);
  private readonly message$ = this.messageB$.asObservable();
  private messageChatB$ = new BehaviorSubject<any>(undefined);
  messageChat$ = this.messageChatB$.asObservable();

  setupStream() {
    this.message$
      .pipe(
        // tap((res) => this.loggingService.log('before concat :', res)),
        // Process each message sequentially
        concatMap((msg) =>
          defer(() => (msg ? this.postChat(msg) : of(msg))).pipe(
            // tap(() => this.loggingService.log('success')),
            // tap((res) => this.loggingService.log('success :', res)),
            tap((res) => this.messageChatB$.next(res))
          )
        )
      )
      .subscribe();
  }

  setMessageChat(val: any) {
    this.messageChatB$.next(val);
  }

  setMessageSubject(value: any) {
    if (value) {
      const paramsPost = this.setDataMessage(value);
      this.loggingService.log('CONST | paramsPost :', paramsPost);
      // NOTE: Add view for front
      this.chatMessageSignal.update((items) => {
        // this.loggingService.log('data in update signal :', items);
        // this.loggingService.log('data length - 1', items[items.length - 1]);
        const data = items.slice();
        const newDate = new Date().getTime() / 1000;

        const newMsg = {
          ...paramsPost,
          message: value,
          isPageSender: true,
          timeStamp: newDate,
        };
        data.push(newMsg);
        this.loggingService.log('data :', data);
        return newMsg.message.type === 'image' || newMsg.message.type === 'video' ? items : data;
      });
      this.messageB$.next(paramsPost);
    }
  }

  setDataMessage(items: any) {
    const currConver = this.currentConversation();
    // this.loggingService.log('CurrentConversation :', currConver);
    // const contact = this.getContactById(currConver.customerId);
    // this.loggingService.log('CONST | contact :', contact);
    const compId = this.getCompState().id;
    const businessId = this.getBizIdState();
    const { firstName, nickname, id } = this.getUserSystemState();
    const identityFullName = `${firstName} ${nickname ? `(${nickname})` : ''}`;

    const messages = {
      ...items,
      type: this.optionsMessageLineService.convertTemplateType(items.type),
    };
    const paramsPost = {
      customerId: currConver.customerId,
      businessId: businessId,
      compId: compId,
      conversationId: currConver.id,
      identityFullName: identityFullName,
      identityId: id,
      messages: messages.length ? messages : [messages],
      receiver: currConver.uuid,
      socialChannelId: currConver.socialChannelId,
    };
    return paramsPost;
  }

  private chatMessageEffect = effect(
    () => {
      // this.loggingService.log('chat message effect :', this.chatMessageSignal());
      let chatArr: any = [];
      if (this.chatMessageSignal()) chatArr = this.chatMessageSignal()?.slice();
      let val: any;
      if (chatArr!.length) {
        const groupedData: GroupedDataItem[] = [];
        // Iterate over data array
        chatArr.forEach((item: any) => {
          // this.loggingService.log('item in foreach =>', item);
          // Convert Unix timestamp to JavaScript Date object
          const timestamp = new Date(item.timeStamp * 1000); // Multiply by 1000 to convert seconds to milliseconds
          // Get the components of the date (day, month, year)

          const dateString = timestamp.toDateString();
          const dateKey = dateString;

          // Find existing group or create a new one
          let group = groupedData.find((g) => g.date === dateKey);
          if (!group) {
            group = { date: dateKey, items: [] };
            groupedData.push(group);
          }

          // Append value to the respective date
          group.items.push(item);

          // Sort items array for the respective date
          group.items.sort((a: any, b: any) => a - b);
        });
        // this.loggingService.log('weowol', groupedData);

        val = groupedData;
      } else {
        val = chatArr;
      }
      this.chatMessageGroupSignal.set(val);
    },
    { allowSignalWrites: true }
  );

  // ANCHOR: GET AUTH STATE //
  getCompState(): ICompanyReturn {
    return this.storageService.get(LS.compState) as ICompanyReturn;
  }

  getBizIdState(): string {
    return this.storageService.get(LS.bizId) as string;
  }

  getUserSystemState(): IUserSystemState {
    return this.storageService.get(LS.userSystemState) as IUserSystemState;
  }
  // ---------------------- //

  getConnectList() {
    // Set Layout Chat (Mobile)
    this.setDisplayLayout(false);
    this.setDisplayMobileLayout(false);

    return this.connectService.getConnectList();
  }

  getChat() {
    return this.chatMessageGroupSignal;
  }

  getNextTokenChat() {
    return this.nextTokenChatSignal;
  }

  getErrorMessage() {
    return this.errorSendMessageSignal;
  }

  // Use for Scroll to Bottom when Blocked or Unblocked
  getScrollToBottom() {
    return this.autoScroll$;
  }

  getConverOnSub() {
    return this.converOnSubSignal;
  }

  getTriggerRefresh() {
    return this.triggerRefresh$;
  }

  getTriggerRefreshValue() {
    return this.triggerRefresh.getValue();
  }

  getTriggerGetConVer() {
    return this.triggerGetConVer$;
  }

  // Contact
  getContactList() {
    return this.contactListSignal;
  }

  getContactById(customerId: string) {
    return this.getContactList()().find((element: any) => element.id === customerId);
  }

  getContactByCondition(conditions: any) {
    return this.contactService.getContactByCondition(conditions);
  }

  getStatusList() {
    return this.contactService.getStatusList();
  }

  getTagsList() {
    return this.contactService.getTagsListAll();
  }

  getCurrentConversation() {
    return this.currentConversation;
  }

  getCompanyDetail() {
    const { id } = this.connectService.getCompanyState() as ICompanyReturn;
    return this.connectService.getCompanyDetail(id);
  }

  getUserRole() {
    return this.authStore.userSystemRole;
  }

  getUserPermission() {
    return this.authStore.subRole;
  }

  getUserTeamId() {
    return this.authStore.teamIds;
  }

  setCurrentConversation(value: any, component?: string) {
    this.loggingService.warn('FUNC | SET Current ConVer [Service] -->', value, component);
    this.currentConversation.set(value);
  }

  setContactList() {
    return this.contactService
      .getContactList()
      .pipe(map((res: any) => this.contactListSignal.set(res.data)))
      .subscribe();
  }

  // Use for Scroll to Bottom when Blocked or Unblocked
  setScrollToBottom(value: boolean) {
    return this.autoScroll.next(value);
  }

  setTriggerRefresh(value: any) {
    return this.triggerRefresh.next(value);
  }

  setTriggerGetConVer(value: boolean) {
    return this.triggerGetConVer.next(value);
  }

  getDisplayLayout() {
    return this.displaySignal;
  }

  setDisplayLayout(value: boolean) {
    return this.displaySignal.set(value);
  }

  getCurrentSocialChanelId() {
    return this.currentSocialChanelId;
  }

  setCurrentSocialChannelId(id: string) {
    // NOTE: Clear signal when select channel //
    this.chatMessageSignal.set(undefined);
    this.clearItemMessageErrorByIndex();
    // -------------------------------------- //

    this.currentSocialChanelId.set(id);
  }

  // Check Size <= 768px === true
  getDisplayMobileLayout() {
    return this.displayMobileSignal;
  }

  setDisplayMobileLayout(value: boolean) {
    return this.displayMobileSignal.set(value);
  }

  // NOTE SubscriptionFeature
  checkFeaturePackage(packageData: any, featureName: string) {
    return this.subFeatureService.checkFeaturePackage(packageData, featureName);
  }

  // NOTE Appsync Func //
  getListConverSationsAws(socialChannelId: string) {
    // list all items
    return from(
      this.client.models.Conversation.list({
        selectionSet: ['customerId', 'socialChannelId', 'id', 'createdAt', 'updatedAt', 'totalUnread', 'messages.*'],
        filter: {
          socialChannelId: {
            contains: socialChannelId,
          },
        },
      })
    ).pipe(
      tap((res) => {
        this.loggingService.warn('listConver', res);
      }),
      map((items: any) => {
        this.loggingService.log('items', items);

        const revertData = items.data.slice().sort((a: any, b: any) => {
          // this.loggingService.log('sort 1 a:', a, '\n b:', b)
          const dateTimeA = new Date(a.updatedAt).getTime();
          // this.loggingService.log('dateTimeA', dateTimeA)
          const dateTimeB = new Date(b.updatedAt).getTime();
          // this.loggingService.log('dateTimeB', dateTimeB)
          return dateTimeB - dateTimeA;
        });
        this.loggingService.warn('after revertData =>', revertData);
        return { ...items, data: revertData };
      })
    );
  }

  getListConverSationsByCusIdAws(cusIdList: string[]) {
    this.loggingService.warn('Func | Get Conver By CusId List', cusIdList);
    const listIdFilter: any[] = [];
    cusIdList.forEach((id) => {
      const conditionsObj = {
        customerId: {
          contains: id,
        },
      };
      listIdFilter.push(conditionsObj);
    });
    return from(
      this.client.models.Conversation.list({
        selectionSet: ['customerId', 'socialChannelId', 'id', 'createdAt', 'updatedAt', 'messages.*'],
        filter: {
          or: [...listIdFilter],
        },
      })
    ).pipe(
      tap((res) => {
        this.loggingService.warn('listConVer With ID', res);
      })
    );
    // this.loggingService.warn('After Convert ==>', listIdFilter);
  }

  getListConversationWithSort(socialChannelId: string, cusIdList?: string[]) {
    // this.errorSendMessageSignal.set([])
    const optionsGetData: any = {
      selectionSet: ['customerId', 'socialChannelId', 'id', 'createdAt', 'updatedAt', 'totalUnread'],
      sortDirection: 'DESC',
    };

    if (cusIdList) {
      const listIdFilter: any[] = [];
      cusIdList.forEach((id) => {
        const conditionsObj = {
          customerId: {
            contains: id,
          },
        };
        listIdFilter.push(conditionsObj);
      });

      optionsGetData.filter = {
        or: [...listIdFilter],
      };
    }

    return from(
      this.client.models.Conversation.listConversationBySocialChannelIdAndLastModified(
        {
          socialChannelId: socialChannelId,
        },
        optionsGetData
      )
    ).pipe(
      concatMap((conVerData: any) => {
        return from(conVerData.data).pipe(
          concatMap((conVer: any) => {
            const { id: conversationId } = conVer;
            // this.loggingService.log('curConverSignal :', this.currentConversation())
            // this.loggingService.log('conversationId :', conversationId)
            // this.loggingService.log('conVer :', conVer)
            //NOTE GET Message By Conversations
            return from(
              this.client.models.Message.listMessageByConversationIdAndCreatedAt(
                {
                  conversationId,
                },
                {
                  sortDirection: 'DESC',
                  limit: 1,
                }
              )
            ).pipe(
              // tap((res) =>
              //   this.loggingService.log('res before merge conver with message data =>', res)
              // ),
              map((message: any) => {
                const messages = message.data[0];
                const messageObj = messages?.message;
                if (messageObj) {
                  return {
                    ...messages,
                    message: {
                      ...messageObj,
                      type: this.optionsMessageLineService.reconvertTemplateType(messageObj.type),
                    },
                  };
                } else {
                  return from([]);
                }
              }),
              map((messageData) => {
                return { ...conVer, messages: messageData };
              })
              // tap((res) =>
              //   this.loggingService.log('res after merge conver with message data =>', res)
              // )
            );
          }),
          toArray(),
          map((conVerWithMessage) => {
            return { ...conVerData, data: conVerWithMessage };
          })
        );
      })
    );
  }

  /**
   * Functions for get list conversations with customer data.
   * @param {string} socialChannelId - The id of social channel for get data.
   * @param {Object} conditions - The object of conditions for set query param for get data.
   * @param {string} nextToken - The next token for get a continue data (optional)
   * @param {number} limit - The number of limit for get data (default at 100)
   */
  getConverSationsWithCustomer(socialChannelId: string, conditions: any = {}, nextToken?: string, limit = 100) {
    const { id } = this.getCompState();
    let queryParams = `?limit=${limit}`;
    if (nextToken) {
      queryParams = `?nextToken=${nextToken}&limit=${limit}`;
    }

    // Convert Conditions To Params
    const keyObj = Object.keys(conditions);
    const filterKey = keyObj
      .map((res) => {
        if (typeof conditions[res] === 'string') {
          // Type String
          return `&${res}=${conditions[res]}`;
        } else {
          // Type Array
          return `&${res}=${conditions[res].toString()}`;
        }
      })
      .join('');

    // NOTE Case Select Tab My Team but not have teamIds
    if (conditions.teamIds) {
      if (!conditions.teamIds.length) {
        return timer(500).pipe(
          map(() => {
            return of([]);
          })
        );
      }
    }

    // Join param for get data
    queryParams = `${queryParams}${filterKey}`;

    return this.http.get(`${SERVER_PATH}/line/conversation/${id}/${socialChannelId}${queryParams}`).pipe(
      map((converObj: any) => {
        this.loggingService.warn('Func | Get Conver  [converObj] ', converObj);
        // Convert Type
        if (converObj) {
          const converReturn = converObj.data.map((res: any) => {
            return {
              ...res,
              messages: {
                ...res.messages,
                message: {
                  ...res.messages.message,
                  type: this.optionsMessageLineService.reconvertTemplateType(res.messages.message.type),
                },
              },
            };
          });

          return {
            ...converObj,
            data: converReturn,
          };
        } else {
          return converObj;
        }
      })
    );
  }

  getConversationsWithCusById(socialChannelId: string, customerId: string) {
    return this.http.get(`${SERVER_PATH}/line/conversation/detail/${socialChannelId}/${customerId}`).pipe(
      tap((res) => this.loggingService.warn('Get Detail Customer', res)),
      map((conVerDetail: any) => {
        return {
          ...conVerDetail,
          messages: {
            ...conVerDetail.messages,
            message: {
              ...conVerDetail.messages.message,
              type: this.optionsMessageLineService.reconvertTemplateType(conVerDetail.messages.message.type),
            },
          },
        };
      })
    );
  }

  getChatByConversationIdAws(conversationId: string, nextToken?: string | null | undefined) {
    this.loggingService.log(
      'FUNC | getChatByConversationIdAws | conversationId ::',
      conversationId,
      '\nnextToken',
      nextToken
    );
    // if(this.currentConversation().conversationId !== conversationId) this.errorSendMessageSignal.set([])
    // if(this.currentConversation() && this.currentConversation().id !== conversationId) this.errorSendMessageSignal.set([])
    // list all items
    return from(
      this.client.models.Message.listMessageByConversationIdAndCreatedAt(
        {
          conversationId,
        },
        {
          sortDirection: 'DESC',
          nextToken: nextToken,
        }
      )
    ).pipe(
      tap((res) => {
        this.loggingService.warn('chat by conver', res);
      }),
      tap((res) => this.nextTokenChatSignal.set(res.nextToken)),
      map((items: any) => {
        // NOTE Sort data
        const revertData = items.data.slice().sort((dataA: any, dataB: any) => {
          const a = new Date(dataA.createdAt).getTime();
          const b = new Date(dataB.createdAt).getTime();
          return a - b;
        });
        this.loggingService.warn('after revertData =>', revertData);
        return revertData;
      }),
      map((value: any) =>
        value.map((element: any) => {
          // this.loggingService.log('map value :', element);
          const message = element.message;
          const convertType = {
            ...message,
            type: this.optionsMessageLineService.reconvertTemplateType(message.type),
          };
          // this.loggingService.log('message :', convertType)
          return {
            ...element,
            message: convertType,
          };
        })
      ),
      // map((res: any) => {
      //   this.loggingService.log('map after next value =>', res);

      //   if (res.length) {
      //     const groupedData: GroupedDataItem[] = [];
      //     // Iterate over data array
      //     res.forEach((item: any) => {
      //       // this.loggingService.log('item in foreach =>', item);
      //       // Convert Unix timestamp to JavaScript Date object
      //       const timestamp = new Date(item.timeStamp * 1000); // Multiply by 1000 to convert seconds to milliseconds
      //       // Get the components of the date (day, month, year)

      //       const dateString = timestamp.toDateString();
      //       const dateKey = dateString;

      //       // Find existing group or create a new one
      //       let group = groupedData.find((g) => g.date === dateKey);
      //       if (!group) {
      //         group = { date: dateKey, items: [] };
      //         groupedData.push(group);
      //       }

      //       // Append value to the respective date
      //       group.items.push(item);

      //       // Sort items array for the respective date
      //       group.items.sort((a: any, b: any) => a - b);
      //     });
      //     this.loggingService.log('weowol', groupedData);

      //     return groupedData;
      //   } else {
      //     return res;
      //   }
      // }),
      tap((res) => {
        this.loggingService.warn('chat by conver after sorting', res);
        if (nextToken) {
          this.chatMessageSignal.update((value: any) => {
            const arr = [...res, ...value];
            return arr;
            // value.shift(res)
            // return value
            // value.splice(0,0, ...res)
            // return value
          });
        } else {
          this.chatMessageSignal.set(res);
        }
      }),
      catchError((err) => {
        this.loggingService.error('CATCH ERROR | getChatByConversationIdAws :', err);
        return throwError(err);
      })
    );
  }

  // ---------------- //

  onCreateConversations() {
    return from(
      this.client.models.Conversation.onCreate({
        selectionSet: ['id', 'socialChannelId', 'customerId'],
        filter: {
          and: [
            {
              socialChannelId: {
                contains: this.currentSocialChanelId(),
              },
            },
          ],
        },
      })
    ).pipe(
      tap((res) => {
        this.loggingService.log(
          'TAP | subscription onCreateConversations [socialChanelId] ===>',
          this.currentSocialChanelId()
        );
        this.loggingService.log('TAP | subscription onCreateConversations :::', res);
        // if (res) {
        //   this.setContactList();
        // }
      }),
      catchError((err) => {
        this.loggingService.error('CATCH ERROR | subscription onCreateConversations :::', err);
        return throwError(err);
      })
    );
  }

  onUpdateConversations() {
    return from(
      this.client.models.Conversation.onUpdate({
        selectionSet: ['id', 'socialChannelId', 'customerId', 'updatedAt', 'createdAt'],
        filter: {
          and: [
            {
              socialChannelId: {
                contains: this.currentSocialChanelId(),
              },
            },
          ],
        },
      })
    ).pipe(
      map((res) => {
        this.loggingService.log(
          'TAP | subscription onUpdateConversations [socialChanelId] ===>',
          this.currentSocialChanelId()
        );
        this.loggingService.log('TAP | subscription onUpdateConversations :::', res);
        if (res) {
          if (res.socialChannelId === this.currentSocialChanelId()) {
            return res;
          } else {
            return null;
          }
        } else {
          return null;
        }
        // if (res) {
        //   this.setContactList();
        // }
      }),
      catchError((err) => {
        this.loggingService.error('CATCH ERROR | subscription onUpdateConversations :::', err);
        return throwError(err);
      })
    );
  }

  onCreateMessage() {
    const { id } = this.getCompState();
    return from(
      this.client.models.Message.onCreate({
        selectionSet: [
          'timeStamp',
          'socialChannelId',
          'messageStatus',
          'messageId',
          'isPageSender',
          'identityId',
          'identityFullName',
          'conversationId',
          'compId',
          'businessId',
          'message.*',
        ],
        filter: {
          and: [
            {
              compId: {
                contains: id,
              },
            },
            {
              socialChannelId: {
                contains: this.currentSocialChanelId(),
              },
            },
          ],
        },
      })
    ).pipe(
      tap((res) => this.loggingService.log('TAP | subscription onCreateMessage :::', res)),
      switchMap((data: any) => {
        if (data) {
          this.loggingService.log('dataconver :', data.conversationId, 'currConver :', this.currentConversation());
          this.converOnSubSignal.set(data);
          if (
            data.conversationId === this.currentConversation()?.id &&
            !data.isPageSender
            // ||
            // data.identityFullName === 'บอท' ||
            // data.message.type === 2 ||
            // data.message.type === 3
          ) {
            this.loggingService.log('if');
            return this.getChatByConversationIdAws(data.conversationId).pipe(map(() => data));
          } else if (
            data.conversationId === this.currentConversation()?.id &&
            (data.identityFullName === 'บอท' || data.message.type === 2 || data.message.type === 3)
          ) {
            return this.getChatByConversationIdAws(data.conversationId).pipe(map(() => data));

            // FIXME: Pushmessage data to chatmessageSignal
            // } else if (
            //   data.conversationId === this.currentConversation()?.id &&
            //   // (
            //   // data.identityFullName === 'บอท'
            //   // ||
            //   //   data.message.type === 2 ||
            //   //   data.message.type === 3)
            // ) {
            //   this.chatMessageSignal.update((items) => {
            //     this.loggingService.log('check chatMessageSignal :', items);
            //     const val = items.slice();
            //     const chatData = {
            //       ...data,
            //       message: {
            //         ...data.message,
            //         type: this.optionsMessageLineService.reconvertTemplateType(
            //           data.message.type
            //         )
            //       }
            //     };
            //     this.loggingService.log('chatData :', chatData);
            //     val.push(chatData);
            //     return val;
            //   });
            //   return of(data);
            // } else if(data.conversationId === this.currentConversation()?.id && (data.message.type === 2 || data.message.type === 3)) {
            //   this.chatMessageSignal.update((items) => {
            //     const val = items.slice();
            //     const chatData = {
            //       ...data,
            //       message: {
            //         ...data.message,
            //         type: this.optionsMessageLineService.reconvertTemplateType(
            //           data.message.type
            //         )
            //       }
            //     };
            //     val[val.length - 1].items.push(chatData);
            //     return val;
            //   });
            //   return of(data);
          } else {
            this.loggingService.log('else');
            return of(data);
          }
        } else {
          return EMPTY;
        }
      }),
      catchError((err) => {
        this.loggingService.error('CATCH ERROR | subscription onCreateMessage :::', err);
        return throwError(err);
      })
    );
  }

  // ---------------- //

  // NOTE: SET //
  setTotalUnread(conversationId: string) {
    this.loggingService.log('FUNC | setTotalUnread =>', conversationId);
    return this.http
      .post<any>(`${SERVER_PATH}/line/read/message/${conversationId}`, {})
      .pipe(
        tap((res) =>
          this.loggingService.log(
            `TAP | setTotalUnred api message : %c${res}`,
            'background-color: green; color: white; font-size: 16px; padding: 2px; border-radius:4px'
          )
        ),
        catchError((err) => {
          this.loggingService.error('CATCH ERROR | setTotalUnred :', err);
          return throwError(err);
        })
      )
      .subscribe();
  }
  // --------- //

  // NOTE: POST //
  // messageStream = signal<any[]>([]);
  // private messageStreamB$ = new BehaviorSubject<any[]>([]);
  // private readonly messageStream$ = this.messageStreamB$.asObservable();
  postChat(value: any, index?: number) {
    this.loggingService.warn('FUNC | postChat[compChatManageService] :', value);

    // const currConver = this.currentConversation();
    // this.loggingService.log('CurrentConversation :', currConver);
    // const contact = this.getContactById(currConver.customerId);
    // this.loggingService.log('CONST | contact :', contact);
    // const compId = this.getCompState().id;
    // const businessId = this.getBizIdState();
    // const { firstName, nickname, id } = this.getUserSystemState();
    // const identityFullName = `${firstName} ${nickname ? `(${nickname})` : ''}`;

    // const messages = {
    //   ...value,
    //   type: this.optionsMessageLineService.convertTemplateType(value.type)
    // };

    // const paramsPost = {
    //   customerId: currConver.customerId,
    //   businessId: businessId,
    //   compId: compId,
    //   conversationId: currConver.id,
    //   identityFullName: identityFullName,
    //   identityId: id,
    //   messages: messages.length ? messages : [messages],
    //   receiver: currConver.uuid,
    //   socialChannelId: currConver.socialChannelId
    // };
    // this.loggingService.log('CONST | paramsPost :', paramsPost);
    // this.loggingService.log('ChatMessageSignal :', this.chatMessageSignal());

    // const msgArr = this.messageStream().slice();
    // msgArr.push(paramsPost);
    // this.messageStream.set(msgArr);
    // const msgArr = this.messageStreamB$.getValue().slice();
    // msgArr.push(paramsPost);
    // this.messageStreamB$.next(msgArr);
    let paramsPost: any = undefined;
    if (index || index === 0) {
      // NOTE IF HAVE INDEX IS DO THIS
      this.loggingService.log('If Index', index);
      this.deleteItemMessageErrorByIndex(index);
      paramsPost = this.setDataMessage(value);
      this.chatMessageSignal.update((items) => {
        const data = items.slice();
        const newDate = new Date().getTime() / 1000;

        const newMsg = {
          ...paramsPost,
          message: value,
          isPageSender: true,
          timeStamp: newDate,
        };
        data.push(newMsg);
        this.loggingService.log('data :', data);
        return newMsg.message.type === 'image' || newMsg.message.type === 'video' ? items : data;
      });
    } else {
      paramsPost = value;
    }

    // NOTE: Add view for front
    // this.chatMessageSignal.update((items) => {
    //   this.loggingService.log('data in update signal :', items);
    //   this.loggingService.log('data length - 1', items[items.length - 1]);
    //   const data = items.slice();
    //   const newDate = new Date().getTime() / 1000;

    //   const newMsg = {
    //     ...paramsPost,
    //     message: value,
    //     isPageSender: true,
    //     timeStamp: newDate
    //   };
    //   data.push(newMsg);
    //   this.loggingService.log('data :', data);
    //   return data;
    // });

    // const getMsgStream = this.messageStream().slice();
    // return of(getMsgStream).pipe(
    // return this.messageStream$.pipe(
    //   // // Wait 500ms after the last input
    //   debounceTime(500),
    //   // // Only emit if the value changes
    //   // distinctUntilChanged(),
    //   switchMap((dataArr) => {
    //     return from(dataArr).pipe(
    //       // delay(2000),
    //       concatMap((res) => {
    //         this.loggingService.log('from concat :', res);
    //         // return EMPTY;
    //         // this.messageStream.update((val) => {
    //         //   const data = val.slice();
    //         //   data.shift();
    //         //   return data;
    //         // });
    //         const msgArr = this.messageStreamB$.getValue().slice();
    //         msgArr.shift();
    //         this.messageStreamB$.next(msgArr);
    //         this.loggingService.log('log res :', res);
    return this.http.post<any>(`${SERVER_PATH}/line/message/push`, paramsPost).pipe(
      tap((res) =>
        this.loggingService.log(
          `TAP | postChat api message : %c${res.message}`,
          'background-color: green; color: white; font-size: 16px; padding: 2px; border-radius:4px'
        )
      ),
      catchError((err: any) => {
        this.loggingService.error('CATCH ERROR | postChat :', err);

        // NOTE: Delete view for front when sending message error
        this.chatMessageSignal.update((items) => {
          this.loggingService.log('catchError | data in update signal :', items);
          // this.loggingService.log(
          //   'catchError | data length - 1',
          //   items[items.length - 1]
          // );
          const data = items.slice();
          data.pop();
          this.loggingService.log('catchError | data :', data);
          return paramsPost.messages[0].type === 2 || paramsPost.messages[0].type === 3 ? items : data;
        });

        if (err.status !== 400 && err.status !== 413) {
          this.loggingService.log('Other Status', paramsPost);
          if (!index && index !== 0) {
            // NOTE : IF HAVE INDEX IS NOT DO THIS
            this.loggingService.log('!Index', index);
            this.updateItemMessageError(paramsPost);
          }
        }
        // this.messageChatB$.next(err);
        return of(err);
        // return this.messageChat$.pipe(
        //   switchMap(() => throwError(() => new Error(err)))
        // );
      })
    );
    //       })
    //     );
    //   })
    // );
    // return this.http
    //   .post<any>(`${SERVER_PATH}/line/message/push`, paramsPost)
    //   .pipe(
    //     tap((res) =>
    //       this.loggingService.log(
    //         `TAP | postChat api message : %c${res.message}`,
    //         'background-color: green; color: white; font-size: 16px; padding: 2px; border-radius:4px'
    //       )
    //     ),
    //     // tap(() => {
    //     //   if (index || index === 0) {
    //     //     // NOTE IF HAVE INDEX IS DO THIS
    //     //     this.loggingService.log('If Index', index);
    //     //     this.deleteItemMessageErrorByIndex(index);
    //     //   }
    //     // }),
    //     catchError((err: any) => {
    //       this.loggingService.error('CATCH ERROR | postChat :', err);

    //       this.chatMessageSignal.update((items) => {
    //         this.loggingService.log('catchError | data in update signal :', items);
    //         this.loggingService.log(
    //           'catchError | data length - 1',
    //           items[items.length - 1]
    //         );
    //         const data = items.slice();
    //         data[data.length - 1].items.pop();
    //         this.loggingService.log('catchError | data :', data);
    //         return data;
    //       });

    //       if (err.status !== 400 && err.status !== 413) {
    //         this.loggingService.log('Other Status', paramsPost);
    //         if (!index && index !== 0) {
    //           // NOTE : IF HAVE INDEX IS NOT DO THIS
    //           this.loggingService.log('!Index', index);
    //           this.updateItemMessageError(paramsPost);
    //         }
    //       }
    //       return throwError(err);
    //     })
    //   );
  }
  // ---------  //

  // NOTE: PATCH //
  patchContact(key: string, data: any) {
    // this.loggingService.log('patchContact => ', data);
    this.loggingService.log('currentConversation => ', this.currentConversation());

    const {
      customerId,
      socialChannelIds,
      uuid,
      compId,
      avatarUrl,
      displayName,
      segmentTags,
      tagIds,
      status,
      isBlock,
      firstName,
      lastName,
      nickName,
      email,
      phone,
      teamId,
      consultant,
      rating,
      customerCompany,
      position,
      note,
      noteMessage,
    } = this.currentConversation();

    const objectSend = {
      socialChannelIds,
      uuid,
      compId,
      avatarUrl,
      displayName,
      segmentTags,
      tagIds,
      status,
      isBlock,
      firstName,
      lastName,
      nickName,
      email,
      phone,
      teamId,
      consultant,
      rating,
      customerCompany,
      position,
      note,
      noteMessage,
    };

    let sendData: any;

    switch (key) {
      case 'contact':
        sendData = {
          ...objectSend,
          ...data,
        };
        break;
      // Change when have team and person
      case 'chat':
        sendData = {
          ...objectSend,
          noteMessage: data,
        };
        break;
      case 'status':
        sendData = {
          ...objectSend,
          status: data,
        };
        break;
      case 'tag':
        sendData = {
          ...objectSend,
          tagIds: data,
        };
        break;
      case 'block':
        sendData = {
          ...objectSend,
          isBlock: data,
        };
        break;
      default:
        sendData = {
          ...objectSend,
        };
        break;
    }
    // this.loggingService.log("sendData", sendData);

    return this.contactService.patchContact(sendData, customerId);
  }
  // ----------- //

  // NOTE: OTHER //
  toDownloadFile(item: any) {
    // this.loggingService.log('FUNC | toDownloadFile :', item)

    let findNameImage: any;
    let nameFile: any;
    // let nameVideo: any;
    // if (item.type === 'image') {
    //   if (item.attachments[0].payload.previewUrl) {
    //     findNameImage = item.attachments[0].payload.previewUrl.split('/');
    //     nameFile = findNameImage[findNameImage.length - 1];
    //     // this.loggingService.warn('this name img =>', nameFile);
    //   }
    // } else if (item.type === 'video') {
    //   if (item.attachments[0].payload.url) {
    //     findNameImage = item.attachments[0].payload.url.split('/');
    //     nameFile = findNameImage[findNameImage.length - 1];
    //   }
    // }
    // else if (item.type === 'audio') {
    if (item.attachments[0].payload.url) {
      findNameImage = item.attachments[0].payload.url.split('/');
      nameFile = findNameImage[findNameImage.length - 1];
    }
    // }
    // this.loggingService.log('name image :', nameFile);

    const imageUrl = `${item.attachments[0].payload.url}`;

    // this.loggingService.log('wow', imageUrl, 'wow nameImg', nameFile);
    return this.http.get(imageUrl, { responseType: 'arraybuffer' }).pipe(
      tap((data: ArrayBuffer) => {
        // this.loggingService.log('tap http ::', data);
        // Create a Blob from the array buffer
        const blob = new Blob([data]);

        // Create a download link
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = item.type === 'file' ? `${item.attachments[0].payload.fileName}` : `${nameFile}`;

        // Append the link to the body and click it to trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
      }),
      catchError((err: any) => {
        // this.loggingService.log('OMG error in service =>', err);
        // this.downloadErrorB$.next('cannotDownload');
        return throwError(err);
      })
    );
  }

  clearItemMessageErrorByIndex() {
    this.errorSendMessageSignal.set([]);
  }

  updateItemMessageError(paramsPost: any) {
    // this.loggingService.log('FUNC | updateItemMessageError => paramsPost :', paramsPost);
    // this.loggingService.log(
    //   'FUNC | updateItemMessageError => currentConversation :',
    //   this.currentConversation()
    // );
    if (paramsPost.conversationId === this.currentConversation().id) {
      this.errorSendMessageSignal.update((value: any) => {
        value.push(paramsPost);
        const convertType = value.map((element: any) => {
          this.loggingService.log('element =>', element);
          return {
            ...element,
            messages: [
              {
                ...element.messages[0],
                type:
                  typeof element.messages[0].type === 'number'
                    ? this.optionsMessageLineService.reconvertTemplateType(element.messages[0].type)
                    : element.messages[0].type,
              },
            ],
          };
        });
        this.loggingService.log('value :', value, 'convertType :', convertType);
        return convertType;
      });
    }
  }

  deleteItemMessageErrorByIndex(index: number) {
    this.loggingService.log('func | deleteitemmessageerrorbyindex :', index);
    // const errorMsgArr = this.errorSendMessageSignal()
    // errorMsgArr.splice(index, 1)
    // this.errorSendMessageSignal.set(errorMsgArr)
    this.errorSendMessageSignal.update((value: any) => {
      this.loggingService.log('signal errorsendmessage :', value);
      value.splice(index, 1);
      this.loggingService.log('update delete value signal errorsendmessage :', value);
      return value;
    });
  }
  // ----------- //
}
