import { inject, Injectable } from '@angular/core';
import { ROUTER_TOKENS } from '../app.routes';
import { IUserSystemState, USER_ROLE } from '../core/auth/auth.interface';
import { AuthStore } from '../core/auth/auth.store';
import { AiFlowCrmSubscription } from '@geeesy/type-aiflow';
import { IFeatureAtt } from '../core/interface/app.interface';
// Service
import { SubscriptionFeaturesService } from '../core/subscription-features/subscription-features.service';
import { LoggingService } from '../core/utils/logging.service';
import { CompanyService } from '../core/company/company.service';
import { UserService } from '../core/user/user.service';

export interface IMenuSideBarEntity {
  icon: string;
  label: string;
  path: string;
  isFeature: boolean;
  featuresAtt: IFeatureAtt | null;
  keyAdmin?: boolean;
  keyStaff?: boolean;
  ownerOnly?: boolean;
  permissionKey?: string;
}

export interface ISubMenuSideBar {
  subMenu?: IMenuSideBarEntity[];
}

export interface IMenuSideBar extends IMenuSideBarEntity, ISubMenuSideBar {}

@Injectable({
  providedIn: 'root',
})
export class MenuSidebarService {
  private readonly loggingService = inject(LoggingService);

  private menuSideBarList: IMenuSideBar[] = [
    // {
    //   icon: 'pi-shopping-cart',
    //   label: 'menu.dashboard',
    //   path: `${ROUTER_TOKENS.BIZ}/${ROUTER_TOKENS.DASHBOARD}`
    // },
    {
      icon: 'pi-building',
      label: 'menu.company',
      isFeature: true,
      featuresAtt: {
        name: AiFlowCrmSubscription.FeatureBusiness.COMPANY,
      },
      path: `${ROUTER_TOKENS.BIZ}/${ROUTER_TOKENS.COMPANY}`,
      ownerOnly: true,
    },
    {
      icon: 'pi-user',
      label: 'menu.users',
      isFeature: false,
      featuresAtt: null,
      path: `${ROUTER_TOKENS.BIZ}/${ROUTER_TOKENS.USER}`,
    },
    // {
    //   icon: 'pi-cog',
    //   label: 'menu.setting',
    //   path: `${ROUTER_TOKENS.BIZ}/${ROUTER_TOKENS.SETTING}`
    // }
  ];

  private menuSideBarInCompList: IMenuSideBar[] = [
    // {
    //   icon: 'pi-shopping-cart',
    //   label: 'menu.dashboard',
    //   path: `${ROUTER_TOKENS.COMP}/${ROUTER_TOKENS.DASHBOARD}`
    // },
    {
      icon: 'pi-comments',
      label: 'menu.chat_management',
      isFeature: true,
      featuresAtt: {
        name: AiFlowCrmSubscription.FeatureChat.LINE,
      },
      path: `${ROUTER_TOKENS.COMP}/${ROUTER_TOKENS.CHAT_MANAGE}`,
      permissionKey: 'chat-manage',
    },
    {
      icon: 'pi-microchip-ai',
      label: 'menu.chatbot',
      isFeature: true,
      featuresAtt: {
        name: AiFlowCrmSubscription.FeatureLINE.CHATBOT,
      },
      path: `${ROUTER_TOKENS.COMP}/${ROUTER_TOKENS.CHATBOT}`,
      permissionKey: 'chatbot-manage',
      subMenu: [
        {
          icon: 'pi-microchip-ai',
          label: 'menu.chatbot_management',
          isFeature: true,
          featuresAtt: null,
          path: `${ROUTER_TOKENS.COMP}/${ROUTER_TOKENS.CHATBOT}/${ROUTER_TOKENS.CHATBOT_MANAGEMENT}`,
        },
        {
          icon: 'pi-key',
          label: 'menu.keywords',
          isFeature: true,
          featuresAtt: null,
          path: `${ROUTER_TOKENS.COMP}/${ROUTER_TOKENS.CHATBOT}/${ROUTER_TOKENS.CHATBOT_KEYWORD}`,
        },
      ],
    },
    {
      icon: 'pi-megaphone',
      label: 'menu.broadcast',
      isFeature: true,
      featuresAtt: {
        name: AiFlowCrmSubscription.FeatureLINE.BROADCAST,
      },
      path: `${ROUTER_TOKENS.COMP}/${ROUTER_TOKENS.BROADCAST}`,
      permissionKey: 'broadcast-manage',
    },
    {
      icon: 'pi-clone',
      label: 'menu.template_reply',
      isFeature: true,
      featuresAtt: {
        name: AiFlowCrmSubscription.FeatureLINE.REPLY_TEMPLATE,
      },
      path: `${ROUTER_TOKENS.COMP}/${ROUTER_TOKENS.TEMPLATE_REPLY}`,
      permissionKey: 'template-reply-manage',
    },
    {
      icon: 'pi-address-book',
      label: 'menu.contact',
      isFeature: true,
      featuresAtt: {
        name: AiFlowCrmSubscription.FeatureLINE.FRIENDS,
      },
      path: `${ROUTER_TOKENS.COMP}/${ROUTER_TOKENS.CONTACT}`,
      permissionKey: 'contact-manage',
      subMenu: [
        {
          icon: 'pi-address-book',
          label: 'menu.contact_management',
          isFeature: true,
          featuresAtt: null,
          path: `${ROUTER_TOKENS.COMP}/${ROUTER_TOKENS.CONTACT}/${ROUTER_TOKENS.CONTACT_MANAGEMENT}`,
        },
        {
          icon: 'pi-tags',
          label: 'menu.contact_tags',
          isFeature: true,
          featuresAtt: null,
          path: `${ROUTER_TOKENS.COMP}/${ROUTER_TOKENS.CONTACT}/${ROUTER_TOKENS.CONTACT_TAGS}`,
        },
      ],
    },
    {
      icon: 'pi-users',
      label: 'menu.users',
      isFeature: false,
      featuresAtt: null,
      path: `${ROUTER_TOKENS.COMP}/${ROUTER_TOKENS.USER}`,
      permissionKey: 'management-manage',
      subMenu: [
        {
          icon: 'pi-user',
          label: 'menu.user_management',
          isFeature: false,
          featuresAtt: null,
          path: `${ROUTER_TOKENS.COMP}/${ROUTER_TOKENS.USER}/${ROUTER_TOKENS.USER_MANAGEMENT}`,
        },
        {
          icon: 'pi-unlock',
          label: 'menu.role_permission',
          isFeature: false,
          featuresAtt: null,
          path: `${ROUTER_TOKENS.COMP}/${ROUTER_TOKENS.USER}/${ROUTER_TOKENS.ROLE_PERMISSION}`,
          keyAdmin: false,
          keyStaff: false,
        },
      ],
    },
    {
      icon: 'pi-sitemap',
      label: 'menu.team_management',
      isFeature: true,
      featuresAtt: {
        name: AiFlowCrmSubscription.FeatureLINE.AGENT,
      },
      path: `${ROUTER_TOKENS.COMP}/${ROUTER_TOKENS.TEAM}`,
      keyAdmin: false,
      keyStaff: false,
    },
    {
      icon: 'pi-folder-open',
      label: 'menu.file_management',
      isFeature: true,
      permissionKey: 'file-manage',
      featuresAtt: {
        name: AiFlowCrmSubscription.FeatureMedia.FILE_MANAGER,
      },
      path: `${ROUTER_TOKENS.COMP}/${ROUTER_TOKENS.FILE_MANAGE}`,
    },
    {
      icon: 'pi-link',
      label: 'menu.connect',
      isFeature: true,
      featuresAtt: {
        name: AiFlowCrmSubscription.FeatureLINE.LINE_CONNECT,
      },
      path: `${ROUTER_TOKENS.COMP}/${ROUTER_TOKENS.CONNECT}`,
      keyAdmin: false,
      keyStaff: false,
    },
  ];

  private userMenuSideBar: IMenuSideBar[] = [
    {
      icon: 'pi-user-edit',
      label: 'menu.profile',
      isFeature: false,
      featuresAtt: null,
      path: `${ROUTER_TOKENS.USER_PROFILE}`,
    },
  ];

  get menuBusiness() {
    return this.menuSideBarList;
  }
  get menuCompany() {
    // const isProd = environment.phase === 'prod';
    // if (isProd) {
    //   return this.menuSideBarInCompList.filter(
    //     (menuSideBar: any) =>
    //       menuSideBar.label !== 'menu.team_management' &&
    //       menuSideBar.label !== 'menu.chat_management'
    //   );
    // } else {
    return this.menuSideBarInCompList;
    // }
  }
  get menuUser() {
    return this.userMenuSideBar;
  }

  // Injectable Service
  companyService = inject(CompanyService);
  authStore = inject(AuthStore);
  userService = inject(UserService);
  subscriptionFeaturesService = inject(SubscriptionFeaturesService);

  getBusinessDetail() {
    return this.companyService.getBusinessDetail();
  }

  getCompanyDetail(compId: string) {
    return this.companyService.getCompanyDetail(compId);
  }

  conditionsPermission(key: string) {
    return this.userService.conditionsPermissionByKey(key);
  }

  conditionsOwnerOnly() {
    const { userRole } = this.authStore.userSystem as IUserSystemState;
    return userRole === USER_ROLE.OWNER;
  }

  conditionRoleAdmin(actions: boolean) {
    const { userRole } = this.authStore.userSystem as IUserSystemState;
    if (userRole === USER_ROLE.ADMIN) {
      return actions;
    } else {
      return true;
    }
  }

  conditionRoleStaff(actions: boolean) {
    const { userRole } = this.authStore.userSystem as IUserSystemState;
    if (userRole === USER_ROLE.STAFF) {
      return actions;
    } else {
      return true;
    }
  }

  conditionHasFeatureInSidebar(featureAtt: IFeatureAtt, companyPackage: any): boolean {
    // this.loggingService.warn('FUNC conditionHasFeatureInSidebar | [companyPackage]',companyPackage);
    // return companyPackage.package.features.some(
    //   (feature: any) => feature.name === featureAtt.name
    // );
    return this.subscriptionFeaturesService.checkFeaturePackage(companyPackage, featureAtt.name);
  }
}
