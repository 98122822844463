import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
// Component
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { LoggingService } from './core/utils/logging.service';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { AiFlowCrmSubscription } from '@geeesy/type-aiflow';

const X_PHASE = import.meta.env.NG_APP_X_PHASE;
const AUTH0_AUDIENCE = import.meta.env.NG_APP_AUTH0_AUDIENCE;

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, MainLayoutComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  private readonly loggingService = inject(LoggingService);
  private readonly title = inject(Title);

  constructor() {
    this.loggingService.log(
      '🅰️0️⃣--AppComponent---------------------------------------------------------------------------------------'
    );
    this.loggingService.info('PHASE', X_PHASE);
    this.loggingService.log('AUTH0_AUDIENCE', AUTH0_AUDIENCE);
    this.loggingService.error('Check @geeesy/type-aiflow', AiFlowCrmSubscription.PackageType.COMPANY);
    const isProd = environment.phase === 'prod';
    this.title.setTitle(`AIFLOW CRM ${isProd ? '' : `(${environment.phase})`}`);
  }
}
