import { Injectable } from '@angular/core';
import { LINE_MESSAGE_TYPE } from '../../core/interface/app.interface';

export interface IMessageListLine {
  type: LINE_MESSAGE_TYPE;
  text: string;
  altText: string;
  contents: any[];
  contentsJson: string;
  attachments: any[];
  emojiList: any[];
  aliasNameList: any[];
  innerHtml: string;
  flexId: string;
  validControl: boolean;
  editControl: boolean;
}

export interface TypeDataUpload {
  typeUpload: LINE_MESSAGE_TYPE;
  fileSize: number;
  fileType?: string;
}

@Injectable({
  providedIn: 'root',
})
export class OptionsMessageLineService {
  reconvertTemplateType(type: number) {
    let returnData = '';
    switch (type) {
      case 0:
        returnData = LINE_MESSAGE_TYPE.JSON;
        break;
      case 1:
        returnData = LINE_MESSAGE_TYPE.TEXT;
        break;
      case 2:
        returnData = LINE_MESSAGE_TYPE.IMAGE;
        break;
      case 3:
        returnData = LINE_MESSAGE_TYPE.VIDEO;
        break;
      case 4:
        returnData = LINE_MESSAGE_TYPE.AUDIO;
        break;
      case 5:
        returnData = LINE_MESSAGE_TYPE.LOCATION;
        break;
      case 6:
        returnData = LINE_MESSAGE_TYPE.STICKER;
        break;
      case 7:
        returnData = LINE_MESSAGE_TYPE.FILE;
        break;
      case 8:
        returnData = LINE_MESSAGE_TYPE.FLEX;
        break;
      case 9:
        returnData = LINE_MESSAGE_TYPE.FLEX_IMAGE;
        break;
      default:
        break;
    }
    return returnData;
  }

  convertTemplateType(type: LINE_MESSAGE_TYPE): number {
    let returnData = 0;

    switch (type) {
      case LINE_MESSAGE_TYPE.JSON:
        returnData = 0;
        break;
      case LINE_MESSAGE_TYPE.TEXT:
        returnData = 1;
        break;
      case LINE_MESSAGE_TYPE.IMAGE:
        returnData = 2;
        break;
      case LINE_MESSAGE_TYPE.VIDEO:
        returnData = 3;
        break;
      case LINE_MESSAGE_TYPE.AUDIO:
        returnData = 4;
        break;
      case LINE_MESSAGE_TYPE.LOCATION:
        returnData = 5;
        break;
      case LINE_MESSAGE_TYPE.STICKER:
        returnData = 6;
        break;
      case LINE_MESSAGE_TYPE.FILE:
        returnData = 7;
        break;
      case LINE_MESSAGE_TYPE.FLEX:
        returnData = 8;
        break;
      case LINE_MESSAGE_TYPE.FLEX_IMAGE:
        returnData = 9;
        break;
      default:
        break;
    }

    return returnData;
  }
}
