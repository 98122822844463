import { IEnv } from '../app/core/interface/app.interface';

export const environment: IEnv = {
  production: false,
  phase: import.meta.env.NG_APP_X_PHASE,
  loggingEnabled: true,
  api: {
    baseUrl: import.meta.env.NG_APP_API_URL,
  },
  auth0: {
    domain: import.meta.env.NG_APP_AUTH0_DOMAIN,
    clientId: import.meta.env.NG_APP_AUTH0_CLIENT_ID,
    authorizationParams: {
      redirect_uri: import.meta.env.NG_APP_AUTH0_REDIRECT_URI,
      audience: import.meta.env.NG_APP_AUTH0_AUDIENCE,
    },
  },
  s3: {
    fileBlob: import.meta.env.NG_APP_S3_FILE_BLOB,
    pubBlob: import.meta.env.NG_APP_S3_PUB_BLOB,
    snapBlob: import.meta.env.NG_APP_S3_SNAP_BLOB,
  },
};
